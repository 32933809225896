import './about.css';
import { useState } from 'react';


const Holder = ({ name , index , change_index }:any) =>{
    return(
        <div className="about-holder" onClick={() => change_index(index)}>{name}</div>
    )
}


export default function About(){
    const tech = [
        {
            name:'Mobile Development',
            languages:'Java and React Native',
            info: 'We develop hybrid applications for both android and ios using React Native a javascript framework that develops fast , small size and good looking applications.We also incorporate native languages such as Java so as to create fast and resource-mindful applications.',
        },
        {
            name:'Web Development',
            languages:'React Javascript , HTML , CSS & JS',
            info: 'We develop responsive , fast and aesthetically pleasing web applications using React a popular javascript framework along with HTML and CSS/Tailwind CSS. This simple site is powered by React.',
        },
        {
            name:'Backend Development',
            languages:'Java & Node Js',
            info: 'We develop the back end of systems using : Java , Python or Node js along with databases such as: postgresql , sql , mongo db ( a non sql based db) and other sql based dbs.We can also easily set up a secure linux server on the fly to host your backend system or host it for you.The simple backend of this site is powered by Node js.',
        },
        {
            name:'Systems Development',
            languages:'Java , C++ & Micro controllers',
            info: 'We develop automated solutions / IOT solutions to institutions and companies using Micro controllers and low level languages such as Java and C.',
        },
        {
            name:'Cyber and Systems Security',
            languages:'Networking',
            info: 'We always have security at heart when building our solutions , we are able to provide penetration testing services so as to check for threats and provide patches to curb the threat exposure in a network or in a system.',
        },
        {
            name:'About Bitdaraja',
            languages:'BITDARAJA TECH',
            info: 'Bitdaraja as software solution was conceptualized in 2018 where we started building B2B and C2B solutions for the Kenyan tech space. We then expanded in terms of resources and man power and officially got registred in 2021.',
        },
        
    ];
    const [ index , set_index ] = useState(0);
    // function to change index
    const change_index = (index:number) => set_index(index);
    return(
        <div className="about">
            <div className="about-l">
            <h1>Tap the buttons below to change category : </h1>
            <br />
                {
                    tech.map(( { name} , index) =>{
                        return (
                            <Holder key={index} name={name} index={index} change_index={change_index}  />
                        )
                    })
                }
            </div>
            <div className="about-r">
                {/* format - title , technlogy user , desc */}
                <h1>{tech[index].name}</h1>
                <h2>{tech[index].languages}</h2>
                <p>{tech[index].info}</p>
            </div>
        </div>
    );
}