import './App.css';
import Start from './components/start/start';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Experiance from './components/exp/exp';

function App() {
  return (
    <>
    <Start />
    <About />
    <Experiance />
    <Contact />
    </>
  );
}

export default App;
