import './contact.css';
import { FaInstagram, FaTwitter, FaLinkedin, FaPhone , FaGithub , FaArrowUp} from 'react-icons/fa';
import ReactLoading from 'react-loading';
import { useState } from 'react';



const Socials = ({ children , link  }: any) => {
    return (
        <div className="social" onClick={() =>{
            const anchor:any = document.createElement('a');
            if(link) anchor.target = `_blank`;
            anchor['href'] = `${link ? link : `tel:+254713023787`}`;
            anchor.click();
        }}>
            {children}
        </div>
    )
}

export default function Contact() {
    const [ loading , setLoading ] = useState(false);
    // post idea
    const post_idea = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const { contact, idea, desc } = event.target;
        const body_data = {
            ...contact.value && { contact: contact.value },
            ...idea.value && {
                idea: idea.value,
            },
            ...desc.value && { desc: desc.value, }
        }
        await fetch(`/idea`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: body_data ? JSON.stringify(body_data) : undefined,
        })
            .then(data => data.json())
            .then(() => {setLoading(false);event.target.reset();})
            .catch(error => {
                setLoading(false);
            })
    }
    // end of fun
    return (
        <div className="contact">
            <div className="contact-l">
                <h1>Got an idea ?, </h1>
                <h2>Pitch it below and you shall be contacted :)</h2>
                <form onSubmit={post_idea}>
                    <input type="text" name="contact" placeholder="Your Email / phone number" required />
                    <input type="text" name="idea" placeholder="Title" required />
                    <input type="text" name="desc" placeholder="Brief Description of the project" required />
                    <input type="submit" value="Submit" />
                </form>
                { loading && <ReactLoading type='cylon' color='#037971' />}
            </div>
            <div className="contact-r">
                <h1>Reach us on:</h1>
                <div className="socials-holder">
                    {<Socials link={`https://github.com/ezratechdev`}>
                        <div className="social-link">
                            <FaGithub size={20} color='#fff' />
                            <div className="linker-action">View</div>
                        </div>
                    </Socials>}
                    {<Socials link={`https://www.instagram.com/bitdaraja/`}>
                        <div className="social-link">
                            <FaInstagram color='#fff' size={20} />
                            <div className="linker-action">View</div>
                        </div>
                    </Socials>}
                    {<Socials link={`https://www.linkedin.com/in/ezra-kipyegon-9a0888228`}>
                        <div className="social-link">
                            <FaLinkedin size={20} color='#fff' />
                            <div className="linker-action">View</div>
                        </div>
                    </Socials>}
                    {<Socials link={`https://twitter.com/ezrakeeps?t=tsnYIUpwU--7ljRn3ZAazw&s=09`}>
                        <div className="social-link">
                            <FaTwitter size={20} color='#fff' />
                            <div className="linker-action">View</div>
                        </div>
                    </Socials>}
                    {<Socials link={undefined}>
                        <div className="social-link">
                            <FaPhone size={20} color='#fff' />
                            <div className="linker-action">Call</div>
                        </div>
                    </Socials>}
                </div>
                <div className='scroll-up-parent'>
                    <div className="scroll-wrapper"
                    onClick={() =>{
                        document.getElementsByClassName('start')[0].scrollIntoView({
                            behavior:'smooth',
                        })
                    }}
                    >
                        <FaArrowUp color='#023436' size={20} />
                    </div>
                </div>
            </div>
        </div>
    )
}