import "./exp.css";

interface expstack{
    title:string,
    desc:string,
    period:string,
    stack:string[],
}


const ExpHolder = ({title , desc , period , stack}:expstack) =>{
    // period separated by hyphen , split it
    const period_array = period.split('-');
    return(
        <div className="exp-holder">
            <h1 className="exp-title">{title}</h1>
            <p className="exp-desc">{desc}</p>
            <div className="exp-stacks">
                {stack && stack.map((data, index) => {
                    return (
                        <div key={index} className="exp-stack-member">{data}</div>
                    );
                })}
            </div>
            <div className="exp-period">
                <div className="period-start"></div>
                <div className="period-line"></div>
                <div className="period-end"></div>
            </div>
            <div className="exp-period-dates">
                <h3>{period_array[0]}</h3>
                <h3>{period_array[1]}</h3>
            </div>
        </div>
    )
}

export default function Experiance(){
    return(
        <div className="exp">
            <h1>Listed Below Are some of the ventures Bitdaraja Tech has worked with :</h1>
            <div className="exp-grid">
                {
                    <ExpHolder 
                    title={"FreeLance"}
                    desc={"Worked as a freelance developer both locally and globally using forums such as Fiverr and Upwork building : web , apps and backend solutions."}
                    period={"2019-present"}
                    stack={['Javascript' , 'React' , 'React Native' , 'Java' , 'DBS' , 'MERN']}
                    />
                }
                 {
                    <ExpHolder 
                    title={"BitDaraja Tech"}
                    desc={"Worked as a founder and software engineer at Bitdaraja Tech , a kenyan registered company building in-house , leasable solutions for the Kenyan market."}
                    period={"2021-present"}
                    stack={['Node js' , 'React' , 'React Native' , 'Typescript']}
                    />
                } {
                    <ExpHolder 
                    title={"Liride Limited"}
                    desc={"Worked as a software developer building and maintaining the website and system for the kenyan Nairobi based moving company"}
                    period={"2022(January)-2022(March)"}
                    stack={['Node js' , 'React', 'Express']}
                    />
                }{
                    <ExpHolder 
                    title={"Jobzy"}
                    desc={"Worked as a Senior sofware engineer working on building hybrid apps , website , desktop apps and the backend"}
                    period={"2022-present"}
                    stack={['Node js' , 'React' , 'React Native' , 'Tauri']}
                    />
                } {
                    <ExpHolder 
                    title={"PeerPay"}
                    desc={"Worked as a Senior sofware engineer working on the back end , hybrid apps , websites and some aspects of the blockchain"}
                    period={"2022-present"}
                    stack={['Node js' , 'React' , 'React Native' , 'Rust']}
                    />
                }
            </div>
        </div>
    )
}

// exp : free lance dev ( 2020 to present)
//  Founder and deveoper at bitdaraja Tech (2021) node js , react 
//  software engineer with Jobzy Inc (2022 - present)
// Software engineer with Peerpay ( 2022 - present)

// Title , desc , period , tech stack