import { useState , useEffect } from 'react';
import './start.css';

const Holder = ({ data , id }: any) => {
    return (
        <div className={`start-holder`}>{(data.length > 0) ? data : "Fetching.."}</div>
    );
}

const LinkerButton = ({ title , classData }:any) =>{
    return(
        <div className="linker" onClick={()=>{
            document.getElementsByClassName(`${classData}`)[0].scrollIntoView({
                behavior:'smooth',
            });
        }}>{title}</div>
    );
}

export default function Start() {
    const [ip, set_ip] = useState('');
    const [isp, set_isp] = useState('');
    // use effect funcs
    const get_json_data = async(link:string) =>{
        await fetch(`${link}`,{
            method:'GET',
            headers: new Headers({
                'Content-Type':'application/json',
            }),
        })
        .then(data => data.json())
        .then((response:any) =>{
            if(`${response.status}` === `200`){
                set_ip(response.ip);
                set_isp(response.isp);
            }
        });
    }
    // useEffect(() => {
    //     get_json_data(`/netinfo`);
    // },[]);
    
    return (
        <div className="start">
            <div className="start-l">
                <div className="start-l-t">
                    { <LinkerButton title={`Home`} classData={'start'} />}
                    { <LinkerButton title={`About`} classData={'about'} />}
                    { <LinkerButton title={`Experience`} classData={'exp'} />}
                    { <LinkerButton title={`Hire Me`} classData={'contact'} />}
                </div>
                <div className="start-l-b">
                    <h2>Welcome to,   </h2>
                    <h1>BITDARAJA TECH</h1>
                    <h3>We offer the following solutions:</h3>
                    <div className="items">
                        <div className="items-wrap">
                            <div className="item">Backend Development</div>
                            <div className="item">Mobile App Development</div>
                            <div className="item">Web Development</div>
                            <div className="item">BlockChain Development</div>
                            <div className="item">Cyber Security Analysis</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="start-r">
                <h1>Your network information:</h1>
                <Holder data={ip} />
                {(isp.length >= 10) && <Holder data={isp} />}
            </div>
        </div>
    )
}